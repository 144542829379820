import {React, useMemo} from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";

import { useTranslation, initReactI18next  } from 'react-i18next';
import i18n from 'i18next';

i18n.use(initReactI18next).init({
	lng: localStorage.getItem('language') || 'fr',
	fallbackLng: 'fr',
	interpolation: {
	  escapeValue: false
	}
  });

//};

function Menu ({idx=0, halo=true}) {
	const { t, i18n } = useTranslation();


	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng); 
		localStorage.setItem('language', lng);
	};


	var lng = i18n.language



	const Language = ({lang, lng}) => 
	<Image
		src={"lang/"+lang+".png"}
		width={lng == lang ? "50px" : "40px"}
		onClick={() => changeLanguage(lang)}
		border-radius="14px"
		margin="0 0 0 6px"
	/>;
	



	return <Theme theme={theme}>

	  	{/*Halo*/}
		{halo && <Image
			md-top="300px"
			lg-top="0px"
			src="backgroung-hero.svg"
			position="absolute"
			right="0px"
			bottom="auto"
			top="-60px"
			z-index="1"
			lg-right="-200px"
			md-width="600px"
		/>}

		
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 36px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" />


			{/*logo recubix*/}
			<Link href="/">
				<Box
					empty-border-width="1px"
					empty-border-style="solid"
					width="60%"
					sm-width="50%"
					display="flex"
					align-items="center"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-color="LightGray"
				>
					<Image 
						height="auto"
						src="recubix3.png" 
						width="100%" 
						min-width="330px"
				     />
				</Box>
			</Link>





			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="200%"
			//	sm-width="50%"
				align-items="center"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override
						slot="Content"
						align-items="center"
						padding="16px 0px 16px 16px"
						md-background="--color-light"
						md-z-index="9"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
					/>
					<Override slot="Children" justify-content="center" display="flex" align-items="center" />
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Box
						display="flex"
						align-items="center"
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
					>
						<Link
							href="/"
							font={idx==0 ?"normal 600 18px/1.5 --fontFamily-googleInter" : "--base"}
							text-decoration-line="initial"
							color={idx==0 ? "--white" : "--grey"}
							margin="0px 20px 0px 20px"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							md-color="--indigo"
						>
							{t('menu.home')}
						</Link>
						<Link
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="/software"
							font={idx=="1" ?"normal 600 18px/1.5 --fontFamily-googleInter" : "--base"}
							text-decoration-line="initial"
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							color={idx=="1" ? "--white" : "--grey"}
							margin="0px 20px 0px 20px"
							opacity="0.8"
						>
							{t('menu.software')}
						</Link>
						<Link
							color={idx=="2" ? "--white" : "--grey"}
							margin="0px 20px 0px 20px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							href="/rubik"
							font={idx=="2" ?"normal 600 18px/1.5 --fontFamily-googleInter" : "--base"}
							text-decoration-line="initial"
							opacity="0.8"
							hover-opacity="1"
							md-color="--indigo"
						>
							{t('menu.rubik')}
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font={idx=="3" ?"normal 600 18px/1.5 --fontFamily-googleInter" : "--base"}
							color={idx=="3" ? "--white" : "--grey"}
							opacity="0.8"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="/mylinks"
							text-decoration-line="initial"
							margin="0px 8px 0px 20px"
						>
							{t('menu.links')}
						</Link>
                        <Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font={idx=="4" ?"normal 600 18px/1.5 --fontFamily-googleInter" : "--base"}
							color={idx=="4" ? "--white" : "--grey"}
							opacity="0.8"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="/contact"
							text-decoration-line="initial"
							margin="0px 8px 0px 20px"
						>
							{t('menu.about')}
						</Link>

						{/*LANGAGES*/}
						<Language lang="fr" lng={lng}/>
						<Language lang="en" lng={lng}/>
						
				</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>

	</Theme>;
};

export default Menu;