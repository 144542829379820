import {React, useMemo} from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, LinkBox, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";

import { useTranslation, initReactI18next  } from 'react-i18next';
import i18n from 'i18next';

i18n.use(initReactI18next).init({
	lng: localStorage.getItem('language') || 'fr',
	fallbackLng: 'fr',
	interpolation: {
	  escapeValue: false
	}
  });


function GroupSoftware ({i=0}) {
	
	const Soft = ({title, url, idx, icon=""}) => <LinkBox
		//sm-margin="0px 12px 0px 0px"
		transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
		href={url}
		text-decoration-line="initial"
		sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
		md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
	//	sm-padding="15px 13px 15px 13px"
		md-text-align="center"
	//	lg-margin="0px 12px 0px 0px"
		padding="5px 15px 5px 15px"
		border-radius="10px"
		lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
		md-padding="15px 16px 15px 16px"
		hover-color="--primary"
		margin="0px 14px 0px 0px"
		//lg-padding="15px 23px 15px 23px"
		//md-width="50%"
		font="--lead"
		hover-background="--color-white"
		border-width="2px"
		border-style="solid"
		border-color={i == idx ? "--color-primary" : "--color-primary"}
		color={i == idx ? "--blue" : "--white" }
		background={i == idx ? "--color-secondary" : "--color-primary"}
		display="flex"
		flex-direction="row"
	>
		{icon && <Image src={icon} width="48px" margin="0 10px 0 0px" />}
		<Text>	{title}</Text>
	
	</LinkBox>;

	return <Box 
	width="100%" 
	//padding="10px 0px 10px 0px"
	display="flex"
>
	<Soft title="Tasky" url="/tasky" idx={1} icon="Tasky_ico.png"/>
	<Soft title="Rubik Teacher" idx={2} url="/rubikTeacher" icon="RubikTeacher_ico.webp" />
	<Soft title="Stackmat Timer" idx={3} url="/virtualStackmatTimer"  icon="stackmatTimer/stackmatTimerIcon.png" />


	</Box>
}


function BannerSoftware ({idx=0}) {


	const Soft = ({title, url, activated=false}) => <Link
		//sm-margin="0px 12px 0px 0px"
		transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
		href={url}
		text-decoration-line="initial"
		sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
		md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
	//	sm-padding="15px 13px 15px 13px"
		md-text-align="center"
	//	lg-margin="0px 12px 0px 0px"
		padding="15px 30px 15px 30px"
		border-radius="10px"
		lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
		md-padding="15px 16px 15px 16px"
		hover-color="--primary"
		margin="0px 14px 0px 0px"
		//lg-padding="15px 23px 15px 23px"
		//md-width="50%"
		font="--lead"
		hover-background="--color-white"
		border-width="2px"
		border-style="solid"
		border-color="--color-primary"
		color={activated ? "--blue" : "--white" }
		background={activated ? "--color-secondary" : "--color-primary"}
	>
		{title}
	</Link>;




	return <Theme theme={theme}>

		<Section background="linear-gradient(264.47deg, #002248 29.39%, #002248 93.49%)" >
			<Override slot="SectionContent" flex-direction="row" />


		
			<Box
					//lg-margin="32px 0px 0px 0px"
					//md-width="100%"
					//md-margin="36px 0px 0px 0px"
					display="flex"
				//	margin="72px 0px 0px 0px"
				width="100%"
				>
					<Box width="80%">

						<GroupSoftware i={idx}/>
					
					</Box>


					<Box							
							justify-content="flex-end"
							//background-color="white"
							width="50%"
							display="flex"
					>
						<Link
							href="https://www.youtube.com/@recubix/videos"
							

						>
							<Image
								srcSet="youtube.png"
								width="120px"
							//	margin="10px 0 0 0px"
							/>
						</Link>
					</Box>

				</Box>


 		</Section>
	</Theme>;
};

export { BannerSoftware, GroupSoftware }