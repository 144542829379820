// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Traductions des langues
const resources = {
  en: {
    translation: {
      menu : {
        home : "Home",
        software: "My software",
        rubik: "Rubik's Cube",
        links: "Links",
        about: "About..."
      },

      homepage: {
        title: "Welcome on RECUBIX",
        desc: 
        {
          text:"You can find on this website:",
          bullet1: "my own software, developped for Windows",
          bullet2: "Rubik's Cube solving methods"
        }
      },

      software: {
        title: "My software",
        windowsTxt : "My software developped for Windows, with Qt in C++",
        organization: "Organization",
        download: "Download",


        tasky: {
          desc: "Advanced task planner, which allows tracking the progress of tasks, as well as storing internet links or files, saving and generating passwords, scheduling reminders, and running automation scripts...",
          title2 :"Task manaager and reminder",
          desc2: "Tasky is an organization and productivity tool that allows you to create task lists in a very simple and intuitive way, store internet links or file links, set reminders, save passwords...",
        },

        rubikTeacher: {
          desc: "A software that helps find the steps to solve a scrambled Rubik's Cube, while also offering various learning methods for solving it",
          title2:"Solve the Rubik's Cube and learn how to do",
          desc2: "Rubik Teacher is a program that not only helps solve a scrambled Rubik's Cube through its edit mode, but also, and most importantly, teaches how to solve it using two different methods through the learning mode",
          modeSolve: "Solve mode",
          modeSolveDesc: "Draw the configuration of a scrambled Rubik's Cube, and the program will find the solution to solve it step by step",
          modeLearn: "Learn mode",
        },

        stackmatTimer: {
          desc: "A software for timing yourself while solving the Rubik's Cube or others",
          title2 :"Rubik's Cube timer",
          desc2: "Virtual Stackmat allows you to time yourself. The Stackmat timer is used in Rubik's Cube competitions, in particular...",
        },

        ti89: {
          desc: "Games developed on the TI-89 in Basic",

          maillon: {
            title: "The Weakest Link",
            desc: "Adaptation of the famous TV game on a calculator, playable with up to 9 players!"
          },

          dcedl: {
            title: "Numbers and Letters",
            desc: "Adaptation of a french TV game, playable alone or with 2 players using the cable"
          }
        }
      },

      rubik: {
        title: "Solve the Rubik's Cube",
        methods: "Solving methods",
        methodBeginner: "Beginner method",
        layerDesc: "A simple solving method with few algorithms to learn",
        methodExpert:  "Expert method",
        fridrichDesc: "One of the fastest methods used by most of the top speedcubers to break records!",
        layer: "Layer by Layer", 
        title2: "Learn how to solve the 3x3x3 Rubik's Cube",
        p001: "Here are some methods to solve the Rubik's Cube by hand. If you're looking for a program that can solve a Rubik's Cube, check out my solver program:",
        numbers: "Some figures",
        invention: "invention by Erno Rubik",
        combinations: "different combinations",
        moves: "Maximum number of possible moves for the solution",
        wr: "Solving time World record",
        title2: "A bit of history before anything else",
        desc: "Invented by Erno Rubik in 1974, who himself took more than a month to solve his own, the Rubik's Cube is a puzzle globally renowned for its difficulty.\nIndeed, there are over 43 quintillion different combinations, with only one corresponding to the solved cube. However, know that no matter how the cube is scrambled, it can always (theoretically) be solved in a maximum of 20 moves.\nFor those who, underestimating the challenge, would like to try solving it themselves by rotating the faces until reaching a solved cube, be aware that it would take you more than 1,200 years to test all the combinations... at a rate of 1 billion combinations tested per second...!\nFeeling up for the challenge? Want to finally solve this cube and reconstruct its six faces into a single, uniform color? (Yes, by the way, that’s the goal of the game!)",

      
      },

      links: {
        title: 'Useful links',

        security: "Security",
        utilities: "Utilities",
        image: "Image",
        video: "Video",
        dev: "Development",
        
        keepass: "Password manager",
        ditto: "Clipboard with history",
        agentRansack: "Tool to search for files",
        greenshot: "Screenshots tool",
        wiztree: "To analyze the space on the hard drive",
        gimp: "Advanced image editing tool",
        paint: "Simple image editing tool",
        davinciresolve:"Video editing",
        notepad: "Text editor",
        qt: "C++ development IDE",
        fork: "Git client",
        python: "",
        filezilla: "FTP client",
        blender: "Modeling, computer animation, and 3D rendering",
        visualstudiocode: "Development IDE",
        cmake: "Cross-platform software development"

      },

      contact: {
        title: "Senior developer"
      }
    }
  },













  fr: {
    translation: {

      menu : {
        home : "Accueil",
        software: "Mes logiciels",
        rubik: "Rubik's Cube",
        links: "Liens",
        about: "A propos..."
      },

      homepage: {
        title: "Bienvenue sur RECUBIX",
        desc: 
        {
          text:"Vous trouverez sur ce site:",
          bullet1: "mes applications persos, développées pour Windows",
          bullet2: "des méthodes de résolution du Rubik's Cube"
        }
      },

      software: {
        title: "Mes logiciels",
        windowsTxt : "Mes logiciels développés pour Windows, avec Qt en C++",
        organization: "Organisation",
        download: "Télécharger",

        tasky: {
          desc: "Planificateur de tâches avancé, qui permet de suivre l'avancement de tâches, mais aussi le stockage de liens Internet ou de fichiers, l'enregistrement et la génération de mots de passe, de programmer des rappels, de lancer des scripts d'automatisation....",
          title2 :"Planificateur de tâches et aide-mémoire",
          desc2: "Tasky est un outil d'organisation et de productivité qui permet de créer des listes de tâches de façon très simple et intuitive, stockez des liens Internet ou vers des fichiers, programmer des rappels, enregistrer des mots de passe...",
        },

        rubikTeacher: {
          desc: "Un logiciel qui permet de trouver les étapes pour résoudre un Rubik's Cube mélangé mais aussi de proposer différentes méthodes d'apprentissage de résolution.",
          title2:"Résolution et apprentissage du Rubik's Cube",
          desc2: "Rubik Teacher est un programme qui permet, non seulement d'aider à résoudre un Rubik's Cube mélangé grâce à son mode d'édition, mais aussi surtout d'apprendre à le résoudre selon 2 méthodes différentes au travers du mode apprentissage",
          modeSolve: "Mode Résolution",
          modeSolveDesc: "Dessinez la configuration d'un Rubik's Cube mélangé et le programme trouvera la solution pour le résoudre pas à pas",
          modeLearn: "Mode Apprentissage",
        },

        stackmatTimer: {
          desc: "Un logiciel pour se chronométrer lors de la résolution du Rubik's Cube notamment",
          title2 :"Chronomètre pour Rubik's Cube",
          desc2: "Virtual Stackmat permet de chronométrer son temps.\nLe Stackmat timer est utilisé en compétition de Rubik's Cube notamment.",
        },

        ti89: {
          desc: "Des jeux développés sur TI-89 en Basic",

          maillon: {
            title: "Le Maillon Faible",
            desc: "Adaptation du jeu télé sur calculatrice, jouable jusqu'à 9 joueurs!"
          },

          dcedl :{
            title: "Des Chiffres et des Lettres",
            desc: "Adaptation du jeu TV de France 3, jouable seul ou à 2 avec le câble"
          },
        },
      },

      rubik: {
        title: "Résolution du Rubik's Cube",
        methods: "Méthodes de résolution",
        methodBeginner: "Méthode débutant",
        layerDesc:"Une méthode de résolution facile avec peu d'algorithmes à apprendre",
        methodExpert:  "Méthode avancée",
        fridrichDesc: "Une des méthodes les plus rapides utilisée par la plupart des meilleurs speedcubers pour battre les records!",
        layer: "Couche par Couche",
        title2: "Apprendre à résoudre le Rubik's Cube 3x3x3",
        p001: "Voici quelques méthodes pour résoudre le Rubik's Cube à la main. Si vous voulez un programme capable de résoudre un Rubik's Cube, consultez mon programme de résolution :",
        numbers: "Quelques chiffres",
        invention: "invention par Erno Rubik",
        combinations: "de combinaisons différentes",
        moves: "nombre de mouvements max possible pour la résolution",
        wr: "Record mondial du temps de résolution",
        title2: "Un peu d'histoire avant tout",
        desc: "Inventé par Erno Rubik en 1974 qui mit lui-même plus d'un mois avant de résoudre le sien, le Rubik's Cube est un casse-tête mondialement réputé pour sa difficulté.\nEn effet, il existe plus de 43 milliards des milliards de combinaisons différentes, dont 1 seule correspond au cube fini.\nSachez cependant que quelle que soit le mélange du cube, celui-ci peut toujours être (théoriquement) résolu en 20 mouvements maximum.\nPour ceux qui, du coup, sous-estimant la difficulté, voudraient essayer de le résoudre par eux-mêmes, en tournant les faces jusqu'à arriver à un cube résolu, sachez qu'il vous faudrait plus de 1200 ans pour tester toutes les combinaisons.. a raison de 1 milliard de combianaisons testées par seconde...!\nEnvie de relever le défi ? Envie de résoudre enfin ce cube et reconstituer ses 6 faces d'une même et unique couleur ? (Oui, au fait, c'est le but du jeu !)",

     
      },

      links: {
        title: 'Liens utiles',

        security: "Sécurité",
        utilities: "Utilitaires",
        image: "Image",
        video: "Vidéo",
        dev: "Développement",


        

        keepass: "Gestionnaire de mots de passe",
        ditto: "Presse-papier avec historique",
        agentRansack: "Outil de recherche de fichiers",
        greenshot: "Outil de capture d'écran",
        wiztree: "Pour analyser l'espace sur le disque dur",
        gimp: "Outil de retouche d'image avancé",
        paint: "Outil de retouche d'image simple",
        davinciresolve:"Montage vidéo",
        notepad: "Editeur de texte",
        qt: "IDE de développement C++",
        fork: "Client Git",
        python: "",
        filezilla: "Client FTP",
        blender: "Modélisation, animation par ordinateur et rendu en 3D",
        visualstudiocode: "IDE de développement",
        cmake: "Construction logicielle multiplateforme"
      },

      contact: {
        title: "Développeur Senior"
      }
    }
  }
};


i18n.use(initReactI18next).init({
  resources,
  lng: 'fr', // Langue par défaut
  fallbackLng: 'en', // Langue de secours si la langue sélectionnée n'est pas disponible
  interpolation: {
    escapeValue: false // React se charge de l'échappement XSS
  }
});

export default i18n;
