import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Icon, LinkBox, Hr, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { FaHeartbeat, FaMedkit, FaCommentMedical, FaClinicMedical } from "react-icons/fa";
import { IoIosWater } from "react-icons/io";
import { IoMdDownload } from "react-icons/io"; 
import { IoMdPrint } from "react-icons/io";
import { IoMdLock  } from "react-icons/io";
import { FaKey  } from "react-icons/fa";
import { FaRegStickyNote } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaFonticons } from "react-icons/fa";

import { Component } from 'react'
import ReactPlayer from 'react-player'

import Menu from "menu";
import { BannerSoftware } from "../bannerSoftware"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

import { useTranslation } from 'react-i18next';

import  RubikTeacher_3_1 from "./RubikTeacher_v3.1.exe"

import Form from "../../form"

class Video extends Component {
    render () {
        return (
        <div className='player-wrapper'>
            <ReactPlayer
            className='react-player fixed-bottom'
            url= 'RubikTeacher.mp4'
            width='100%'
            height='100%'
            controls = {true}
			playing={true}
			loop={true}

            />
        </div>
        )
    }
}

export default (() => {

	const { t, i18n } = useTranslation();	

	return <Theme theme={theme}>


		<Menu idx="1" halo={false}/>

		<BannerSoftware idx={2}/>




		<Section padding="0 0 0 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="30%"
				flex-direction="column"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="10px"
					lg-max-height="366px"
					src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
					left="-140px"
					max-width="100%"
					object-fit="cover"
					srcSet="RubikTeacher_ico.webp"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-style="solid"
				width="70%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				flex-direction="column"
				align-items="flex-start"
			>
				<Text
					md-margin="0px 0px 40px 0px"
					lg-margin="0px 0px 40px 0px"
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--indigo"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
					id="software"
				>
					Rubik Teacher
				</Text>


				<Text
					width="90%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline4"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					{t('software.rubikTeacher.title2')}
				</Text>
				<Box display="flex" margin="0px 0px 0px 0px" align-items="center" sm-width="100%">
					<Image width="24px" height="24px"
					 	src="windows.png" margin="0px 15px 0px 0px" />
					<Text
						margin="0px 0px 0px 0px"
						color="--primary"
						font="--lead"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
					>
						Windows
					</Text>
				</Box>
				<Box display="flex" margin="0px 0px 0px 0px" align-items="center" sm-width="100%">
					<Image width="32px" height="32px" 
					src="rubik_bw.png" margin="0px 15px 0px 0px" />
					<Text
						margin="0px 0px 0px 0px"
						color="--primary"
						font="--lead"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
					>
						Rubik's Cube
					</Text>
				</Box>
				

				<Text
					font="--base"
					opacity=".7"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 25px 0px"
					color="--dark"
				>
					{t('software.rubikTeacher.desc2')}
				</Text>

			
				<Link
					href={RubikTeacher_3_1}
					sm-padding="15px 20px 15px 20px"
					hover-background="rgba(5, 165, 255, 0)"
					border-width="1px"
					border-style="solid"
					font="--lead"
					sm-margin="0px 22px 0px 0px"
					border-color="rgba(255, 255, 255, 0.3)"
					md-width="100%"
					md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					text-decoration-line="initial"
					color="--white"
					margin="0px 44px 0px 0px"
					hover-color="--primary"
					hover-border-color="--color-primary"
					md-text-align="center"
					background="--color-primary"
					padding="15px 30px 15px 30px"
					border-radius="10px"
					sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
				>
					<FontAwesomeIcon icon={faDownload} size="1x"/>   
						{t('software.download')} Rubik Teacher 3.1
				</Link>
			</Box>
		</Section>



		<Section >
			<Override slot="SectionContent" justify-content="center" align-items="center" />
			<iframe width="1080" height="700"
				src="https://www.youtube.com/embed/Ig7HRs3gBnk">
			</iframe>
		</Section>

		<Section padding="80px 0 24px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				flex-direction="column"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="10px"
					lg-max-height="366px"
					src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
					left="-140px"
					max-width="100%"
					object-fit="cover"
					srcSet="rt_edition.jpg"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-style="solid"
				width="50%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				flex-direction="column"
				align-items="flex-start"
			>

				<Text
					width="85%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					{t('software.rubikTeacher.modeSolve')}
				</Text>
				<Text
					font="--base"
					opacity=".7"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 25px 0px"
					color="--dark"
				>
					{t('software.rubikTeacher.modeSolveDesc')}
				</Text>
			</Box>

		</Section>

		
		<Section padding="0 0 24px 0" sm-padding="80px 0 24px 0" md-padding="0 0 24px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-style="solid"
				width="50%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				flex-direction="column"
				align-items="flex-start"
			>

				<Text
					width="85%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					{t('software.rubikTeacher.modeLearn')}
				</Text>
				<Text
					font="--base"
					opacity=".7"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 25px 0px"
					color="--dark"
				>
					Apprenez les méthodes de résolution du Rubik's Cube à travers 2 méthodes selon le niveau
				</Text>
				<ul>
					<li>Couche par couche (débutant)</li>
					<li>Méthode Jessica Fridrich (avancée)</li>
				</ul>
			</Box>
			<Box
				width="50%"
				flex-direction="column"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="10px"
					lg-max-height="366px"
					src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
					left="-140px"
					max-width="100%"
					object-fit="cover"
					srcSet="rt_learn.jpg"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>

		</Section>


	






	<Section>
	<div>
	    
        	
        <ul>
            <li>
                <b>Méthode <em>Couche par couche</em></b> : pour les débutants qui permet de résoudre en une centaine de mouvements
            </li>
            <li>
                <b>Méthode <em>Fridrich</em></b> : méthode experte, qui permet de résoudre le cube en moins de 60 mouvements et très largement utilisée en compétition pour descendre sous la barre des 10 secondes!
            </li>
        </ul>
    </div>





    <div>
        <h2 class="text-primary">Présentation du logiciel</h2>
    </div>

    <ol>
		<li>
            <b>Cube initial</b>
            <br />
            Cette zone sert à coloriser le cube. La palette permet de "peindre" le cube sur la vue éclatée tandis que les différents boutons servent respectivement à:
            <ol type="a">
                <li>Remplir le cube en mode "résolu"</li>
                <li>Vider complètement le cube</li>
                <li>Vérifier la validité du cube</li>
            </ol>
        </li>
        <li>
            <b>Mélange aléatoire</b>
            <p>Choisissez le nombre de mouvements aléatoires avant de mélanger le cube</p>
        </li>
        <li>
            <b>Mouvements</b>
            <p>Cliquez sur l'un des 36 mouvements possibles pour animer le cube 3D, le mélanger, et éventuellement, le résoudre!</p>
        </li>
        <li>
            <b>Zone d'affichage du cube 3D</b>
            Les différents boutons servent respectivement à:
            <ol type="a">
                <li>recentrer le cube à sa position d'origine</li>
                <li>Animer le cube on/off</li>
                <li>Nettoyer la zone de texte</li>
            </ol>
        </li>
        <li>
            <b>Zone d'affichage de la solution et des différents algorithmes de résolution</b>
        </li>
        <li>
            <b>Cube Cible</b>: 
            <p>Décrit la configuration visée aux différentes étapes de résolution</p>
        </li>

        <li>
            <b>Résolution</b>
            Les boutons servent à:
            <ol type="a">
                <li>Comparer les différentes solutions disponibles pour voir combien de mouvements sont nécessaires avec chacune des méthodes</li>	
                <li>Résoudre le cube!</li>
            </ol>
        </li>

        <li>
            <b>Lecteur d'algorithmes et contrôle de la vitesse du cube 3D</b> 
        </li>
    </ol>

    <div>
        <h2 class="text-primary">Mode édition</h2>
        <div>
            Si vous avez un Rubik's Cube mélangé et que vous voulez trouver la solution pour le résoudre, ce programme peut le résoudre pour vous:
        </div>

        <ul>
            <li>Editez le cube en vue éclatée (1.d) pour obtenir le même cube à l'écran que le votre.</li>
            <li>Vérifiez la validité du cube (1.c)</li>
            <li>Choisissez la méthode de résolution (Friedrich pour la plus rapide) et affichez la solution (7.b)</li>
            <li>Cela affichera les mouvements à reproduire à chaque étape de a résolution que vous pouvez re/jouer à une vitesse adaptée grâce au lecteur d'algorithmes (8)</li>
        </ul>
    </div>

    <div class="row">
        <h2 class="text-primary">Mode apprentissage</h2>
        <div>
            Si vous souhaitez apprendre à résoudre le Rubik's Cube, sachez que le programme affiche la solution ainsi que les différentes étapes de la résolution. 
        </div>

        <div>
            Il permet aussi de naviguer parmi les différents algorithmes de résolution:
        </div>
        <div>
            Si vous mélangez le cube (3) et affichez la solution (7.a/b), vous verrez apparaître (5) les algorithmes utilisés pour résoudre chaque étape de la solution.
        </div>
        <div>
            En cliquant sur une étape de la solution (5), la vue 3D du cube (4) se mettra à jour à l'état initial de l'étape. La vue (1) affichera le cube correspondant en vue éclatée. La vue (6) affiche l'état recherché et atteint en fin d'étape (les cases grises étant considérées comme neutres à cette étape de résolution)
        </div>
        <div>
            Pour connaitre tous les algorithmes nécessaires à la résolution de chaque étapes de chacune des deux méthodes, vous pouvez naviguer parmi eux en choisissant l'algorithme dans la section (7)
        </div>
    </div>

	</Section>




	<Section padding="160px 0 24px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
			<Form title='[Rubik Teacher]'/>
		</Section>

	</Theme>;
});