import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Icon, LinkBox, Hr, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { FaHeartbeat, FaMedkit, FaCommentMedical, FaClinicMedical } from "react-icons/fa";
import { IoIosWater } from "react-icons/io";
import Menu from "menu";

import { useTranslation } from 'react-i18next';

import { GroupSoftware } from "../Software/bannerSoftware"



export default (() => {


	const { t, i18n } = useTranslation();

	
	const Soft = ({title, url, icon="", activated=false}) => <LinkBox
		//sm-margin="0px 12px 0px 0px"
		transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
		href={url}
		text-decoration-line="initial"
		sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
		md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
	//	sm-padding="15px 13px 15px 13px"
		md-text-align="center"
	//	lg-margin="0px 12px 0px 0px"
		padding="5px 15px 5px 15px"
		border-radius="10px"
		lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
		md-padding="15px 16px 15px 16px"
		hover-color="--primary"
		margin="0px 14px 0px 0px"
		//lg-padding="15px 23px 15px 23px"
		//md-width="50%"
		font="--lead"
		hover-background="--color-white"
		border-width="2px"
		border-style="solid"
		border-color="--color-primary"
		color={activated ? "--blue" : "--white" }
		background={activated ? "--color-secondary" : "--color-primary"}
		display="flex"
		flex-direction="row"
	>
		{icon && <Image src={icon} width="48px" margin="0 10px 0 0px" />}
		<Text>	{title}</Text>
	
	</LinkBox>;



	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Recubix.fr
			</title>
			<meta name={"description"} content={"Recubix: applications par Sébastien Labonne."} />
			<meta property={"og:title"} content={"Recubix.fr"} />
			<meta property={"og:description"} content={"Recubix: applications par Sébastien Labonne."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/OGimage.png?v=2021-09-21T16:25:40.647Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon.svg?v=2021-09-21T16:18:29.306Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon270.svg?v=2021-09-21T16:18:07.731Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>

		<Menu />

		
	
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 20px 0" quarkly-title="HeroBlock" md-padding="36px 0 20px 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				//empty-min-width="64px"
				//empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				//lg-padding="0px 0px 0px 0px"
				//md-width="100%"
				justify-content="center"
				width="80%"
				//padding="0px 10px 0px 0px"
				//md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 8px 0px"
					color="--grey"
					lg-text-align="left"
					font="--base"
					text-transform="uppercase"
				//	lg-margin="0px 0px 8px 0px"
					sm-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
				>
					
				</Text>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					{t('homepage.title')}<span style={{color: "white"}}>.fr</span>
				</Text>


				<Text
					font="--headline4"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
				//	margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
				>
				
				</Text>
				<Box
				//	lg-margin="32px 0px 0px 0px"
					md-width="100%"
					//md-margin="36px 0px 0px 0px"
					display="flex"
					//margin="72px 0px 0px 0px"
				>
					<Link
						sm-margin="0px 22px 0px 0px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						href="/software"
						text-decoration-line="initial"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						sm-padding="15px 13px 15px 13px"
						md-text-align="center"
						//lg-margin="0px 32px 0px 0px"
						padding="15px 30px 15px 30px"
						border-radius="10px"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					//	md-padding="15px 16px 15px 16px"
						hover-color="--primary"
						color="--white"
						margin="0px 15px 0px 0px"
						lg-padding="15px 23px 15px 23px"
						md-width="50%"
						background="--color-primary"
						font="--lead"
						hover-background="--color-white"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
					>
						{t('software.title')}
					</Link>
					<Link
						sm-margin="0px 22px 0px 0px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						href="/rubik"
						text-decoration-line="initial"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						sm-padding="15px 13px 15px 13px"
						md-text-align="center"
						lg-margin="0px 32px 0px 0px"
						padding="15px 30px 15px 30px"
						border-radius="10px"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-padding="15px 16px 15px 16px"
						hover-color="--primary"
						color="--white"
						margin="0px 44px 0px 0px"
						lg-padding="15px 23px 15px 23px"
						md-width="50%"
						background="--color-primary"
						font="--lead"
						hover-background="--color-white"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
					>
						{t('rubik.title')}
					</Link>
				</Box>
				
				<Box
					width="20%"
					flex-direction="column"
					md-width="100%"
					empty-min-width="64px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					padding="16px 16px 16px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="space-between"
					empty-min-height="64px"
					empty-border-width="1px"
				>
		<Link href="https://www.youtube.com/@recubix/videos">
								<Image
									srcSet="youtube.png"
									width="120px"
								/>
							</Link>

							</Box>
			</Box>

			
	
			<Box
				empty-border-style="solid"
				width="20%"
				display="flex"
				justify-content="flex-end"
				empty-border-width="1px"
				//empty-min-height="64px"
				empty-border-color="LightGray"
				align-items="center"
				//md-width="100%"
				md-order="-1"
				//empty-min-width="64px"
			>
				<Image
				//	lg-max-height="400px"
					width="100%"
				//	max-height="450px"
					md-margin="0px 0px 30px 0px"
					src="https://uploads.quarkly.io/6123949e957019001e284458/images/iPhone%20X.png?v=2021-09-21T20:44:05.312Z"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
					srcSet="home.png"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>

		</Section>


		<Section padding="10px 0 10px 0" >
		<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />

		<Text
					font="--base"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
				//	margin="0px 0px 0px 0px"
					//color="--white"
					opacity="1"
				>
					{t('homepage.desc.text')}
					<ul>
						<li>
							{t('homepage.desc.bullet1')}
						</li>
						<li>
							{t('homepage.desc.bullet2')}
						</li>
					</ul>
				</Text>

</Section>






		<Link
			href="/software"
			style={{ textDecoration: 'none' }}
		>
	
			<Section  >
				<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
				<Box
					width="20%"
					flex-direction="column"
					md-width="100%"
					empty-min-width="64px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					padding="16px 16px 16px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="space-between"
					empty-min-height="64px"
					empty-border-width="1px"
				>
					<Image
						position="static"
						lg-left="0px"
						md-width="100%"
						border-radius="10px"
						lg-max-height="366px"
						left="-140px"
						max-width="80%"
						object-fit="cover"
						srcSet="software.png"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					justify-content="space-between"
					empty-min-height="64px"
					empty-border-style="solid"
					width="80%"
					padding="50px 16px 16px 16px"
					display="flex"
					md-width="100%"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-color="LightGray"
					flex-direction="column"
					align-items="flex-start"
				>

					<Text
						width="85%"
						lg-width="100%"
						lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
						md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--indigo"
						sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
					>
						{t('software.title')}
					</Text>

					<GroupSoftware />
		


				</Box>

			</Section>
		</Link>















		<Link
			href="/rubik"
			style={{ textDecoration: 'none' }}
		>
	
			<Section padding="10px 0 24px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
				<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
				<Box
					width="20%"
					flex-direction="column"
					md-width="100%"
					empty-min-width="64px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					padding="16px 16px 16px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="space-between"
					empty-min-height="64px"
					empty-border-width="1px"
				>
					<Image
						position="static"
						lg-left="0px"
						md-width="100%"
						border-radius="10px"
						lg-max-height="366px"
						left="-140px"
						max-width="80%"
						object-fit="cover"
						srcSet="rubik2.png"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					justify-content="space-between"
					empty-min-height="64px"
					empty-border-style="solid"
					width="80%"
					padding="50px 16px 16px 16px"
					display="flex"
					md-width="100%"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-color="LightGray"
					flex-direction="column"
					align-items="flex-start"
				>

					<Text
						width="85%"
						lg-width="100%"
						lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
						md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--indigo"
						sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
					>
						{t('rubik.title')}
					</Text>
					<Box
						//lg-margin="32px 0px 0px 0px"
						//md-width="100%"
						//md-margin="36px 0px 0px 0px"
						display="flex"
						//	margin="72px 0px 0px 0px"
						width="100%"
					>
		

						<Box 
							width="100%" 
							//padding="10px 0px 10px 0px"
							display="flex"
						>
						<Soft title="Méthode Couche par Couche" url="/methodLayer" />
						<Soft title="Méthode Fridrich" url="/methodFridrich" />
						
	
						</Box>
					</Box>

				</Box>
			</Section>
		</Link>




	</Theme>;
});