
import { Theme, Link, Image, Box, Section, Text , Button, LinkBox} from "@quarkly/widgets";



import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { React, useState } from 'react';

import { useTranslation  } from 'react-i18next';



function Form ({title}) 
{

	const [message, setMessage] = useState('');

	const handleSubmit = (e) => {
	  e.preventDefault();
	  
	  // Créez l'URL mailto avec le message
	  const email = 'recubix.fr@gmail.com'; // Remplacez par l'adresse email de destination
	  const subject = title; // Remplacez par le sujet du mail
	  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
  
	  // Ouvre le lien mailto dans une nouvelle fenêtre
	  window.location.href = mailtoLink;
	};
  
	return (


	


<Box
	padding="66px 30px 66px 30px"
	flex-direction="column"
	sm-padding="30px 15px 30px 15px"
	sm-border-radius="12px"
	align-items="center"
	background="--color-light"
	border-radius="30px"
	justify-content="center"
	width="100%"
	display="flex"
	margin="0px 0px 0px 0px"
>

	<Text font="--lead" color="--primary" text-transform="uppercase" margin="0px 0px 20px 0px">
		 Une idée d'amélioration ?
	</Text>
	<Text font="--lead" color="--primary" text-transform="uppercase" margin="0px 0px 20px 0px">
		Un bug ?
	</Text>
	<Text font="--lead" color="--primary" text-transform="uppercase" margin="0px 0px 20px 0px">
		Un encouragement ?
	</Text>
	<Text
		text-align="center"
		lg-font="normal 600 30px/1.3 --fontFamily-googleInter"
		md-font="normal 600 25px/1.3 --fontFamily-googleInter"
		sm-font="normal 600 19px/1.3 --fontFamily-googleInter"
		margin="0px 0px 35px 0px"
		font="normal 600 39px/1.3 --fontFamily-googleInter"
		color="--dark"
	>
		Ecrivez-moi
	</Text>
	<Box
		border-color="rgba(5, 0, 11, 0.1)"
		border-radius="16px"
		border-width="1px"
		border-style="solid"
		sm-width="100%"
		background="#ffffff"
		padding="12px 12px 12px 12px"
		flex-direction="column"
		display="flex"
		
	>
			  <form onSubmit={handleSubmit}>
		
 
		  <textarea
			id="message"
			value={message}
			onChange={(e) => setMessage(e.target.value)}
			required
			placeholder="Votre texte ici"
			sm-width="100%"
			sm-padding="12px 14px 12px 14px"
			border-width="0px"
			padding="14px 16px 14px 16px"
			width="100%"
			md-width="250px"
			name="txt"
			background="rgba(255, 255, 255, 0)"
			cols="150"
			rows="10"
		  />

		<button 
		type="submit"
		sm-width="100%"
		border-width="1px"
		border-color="--color-primary"
		font="--base"
		padding="14px 53px 14px 53px"
		sm-padding="12px 15px 12px 15px"
		hover-background="--color-light"
		hover-color="--primary"
		border-style="solid"
		background="--color-primary"
		border-radius="10px"
		md-padding="14px 30px 14px 30px"
		>
			Envoyer
		</button>

		
						
	  </form>
	</Box>
</Box>



	);
};

export default Form;