import { React, useEffect } from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Icon, LinkBox, Hr, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { FaHeartbeat, FaMedkit, FaCommentMedical, FaClinicMedical } from "react-icons/fa";
import { IoIosWater } from "react-icons/io";
import Menu from "menu";
import { BannerFridrich } from "./bannerFridrich";


import img_F_01 from "./F_01.jpg"
import img_F_02 from "./F_02.jpg"
import img_F_03 from "./F_03.jpg"
import img_F_04 from "./F_04.jpg"


export default (() => {



	return <Theme theme={theme}>

		<Menu idx="2" halo={false}/>

        <BannerFridrich idx={1}/>

		<Section >
            <Override slot="SectionContent" flex-direction="row" />
			<Text
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 40px 0px"
				margin="60px 0px 80px 0px"
				font="--headline2"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
				id="rubik"
                width="70%"
			>
				Méthode Fridrich
			</Text>
            <Image 
                srcSet="rubik_piano.jpg" 
                border-radius="40px"
                width="40%"
           //     margin="0 0px 0 50px"
            />
		</Section>




		<Section lg-padding="0 0 0 0" padding="0 0 0 0" md-padding="0 0 0 0">

<Text font="--base">
				 <p>
            Du nom de son inventrice, Jessica Fridrich, c'est LA méthode ultime, LA méthode la plus utilisée dans les compétitions de speedcubing (résolution la plus rapide possible). 
            Une fois parfaitement maitrisée, elle permet de descendre sous la barre des 10 secondes pour les meilleurs... !<br />
            Je ne vous cache pas qu'elle est un peu plus compliquée é apprendre que la méthode <em>"Couche par couche"</em> car elle demande l'apprentissage de pas moins de 78 algorithmes... c'est le prix é payer pour une résolution qui tourne aux alentours de <span class="nombre_mvts">60 mouvements.</span><br />
            Avant de vous précipiter sur cette méthode, je vous conseille de commencer par la méthode <em>"Couche par couche"</em>, beaucoup plus simple à apprendre que celle-ci.
        </p>
			

        <div class="puce">
            <p>
                Cette méthode se décompose en 4 étapes :
                <ul>
                    <li><a href="#croix">Croix</a></li>
                    <li><a href="#f2l">F2L</a></li>
                    <li><a href="#oll">OLL</a></li>
                    <ul>
                        <li><a href="/methodFridrichOLL">OLL algos</a></li>
                    </ul>
                    <li><a href="#pll">PLL</a></li>
                    <ul>
                        <li><a href="/methodFridrichPLL">PLL algos</a></li>
                    </ul>
                </ul>
            </p>
        </div>



        <table>
            <tr>
                <td>
                    <h2 id="croix">1/ Croix de la premiére face</h2>
                    <p class="description">
                        Cette étape est exactement la méme que pour la méthode <em>"Couche par couche"</em> : elle consiste a construire une croix orientée sur la premiére face de son choix.<br />
                        Débrouillez-vous... mais sachez que la résolution de la croix peut presque toujours se faire en 7 mouvements maximum !
                        <div class="quicker">
                            {/* <!-- <p><img src="rocket.png" class="imageflottante" alt="Image flottante" /></p> --> */}
                            <div class="quicker_p">
                                <h3>Pour aller plus vite:</h3>
                                <ul>
                                    <li>Faites la croix sur la face du bas : cela évite d'avoir à retourner le cube pour l'étape suivante et permet ainsi de gagner ainsi de précieuses fractions de secondes !</li>
                                    <li>Apprenez à faire la croix en orientant les arétes de façon relative : il est inutile de recentrer la face à chaque aréte résolue. En connaissant la position relative de chaque couleur par rapport aux autres, il est ainsi possible de reconstituer la croix et de ne la rencentrer qu'une seule fois avec des mouvemnets U.</li>
                                </ul>
                            </div>
                        </div>
                    </p>
                </td>
                <td>
                    <p>
                        <img src={img_F_01} alt="Cube" />
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <h2 id="f2l">2/ <acronym title="First 2 Layers">F2L</acronym> : First 2 Layers</h2>
                    <p class="description">
                        On va ici regrouper deux étapes de la méthode <em>"Couche par couche"</em> en une seule : on termine la résolution de la premiére face tout en complétant en même temps la couronne centrale.<br />
                        On commence par retourner la cube, de façon à avoir la croix de la première face sur la face du bas. On va ainsi résoudre les deux premières couches à l'envers. Cela permet d'avoir une meilleure visibilité sur la face du haut, celle par laquelle on finira le cube.<br />
                        On s'intéresse ici à résoudre les 4 slots : un slot est l'association d'un coin et de l'arète associée sur la couronne centrale.<br />
                        Pour résoudre les 4 slots et compléter ainsi cette étape, la méthode consiste à regrouper les pièces du slot correspondant sur la face du haut avant de le faire "glisser" dans son emplacement définitif sur les 2 couches du bas.
                        <div class="quicker">
                            {/* <!-- <p><img src="rocket.png" class="imageflottante" alt="Image flottante" /></p> --> */}
                            <div class="quicker_p">
                                <h3>Pour aller plus vite:</h3>
                                <ul>
                                    <li>Un terme barbare : le Multislotting. Cette optimisation consiste, pendant la résolution d'un slot, à anticiper sur la résolution du slot suivant de faéon à en faciliter l'insertion .<br />
                                    Sachez que le programme de résolution "Rubik Teacher" peut détecter certaines situations de ce type... réservé aux pros uniquement, mais c'est ici qu'on peut gagner (ou perdre) du temps !
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </p>
                </td>
                <td>
                    <p>
                        <img src={img_F_02} alt="Cube" />
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <h2 id="oll">3/ <acronym title="Orientation Last Layer">OLL</acronym> : Orientation Last Layer</h2>
                    <p class="description">
                        Cette étape consiste à résoudre les couleurs de la dernière face, sans se préoccuper de la couleur des côtés de la troisième couche (celle du haut)<br />
                        En fonction de l'étape précédente, on peut se retrouver dans une des 57 configurations possibles, soit autant d'algorithmes à apprendre...<br />
                        <a href="/methodFridrichOLL" >Cliquez ici pour la liste des algorithmes</a>
                        <div class="quicker">
                        {/* <!-- <p><img src="rocket.png" class="imageflottante" alt="Image flottante" /></p> --> */}
                            <div class="quicker_p">
                                <h3>Pour aller plus vite:</h3>
                                <ul>
                                    <li>Ici, pas de secret, une fois l'algorithme à utiliser repéré, il faut s'entrainer à le faire le plus rapidement possible. <br />
                                        C'est ici que les "Finger Tricks" interviennent : ce sont des successions de mouvements trés rapides à exécuter avec les doigts. Apprenez à enchaéner les mouvements sans temps mort, à commencer le mouvement suivant avant la fin du précédent, à utiliser les doigts des deux mains pour enchaîner plus rapidement les algorithmes.<br />
                                        Regardez donc des vidéos de speedcubing sur Internet, vous comprendrez par vous-méme... A condition aussi d'avoir un cube adapté !
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </p>
                </td>
                <td>
                    <p>
                        <img src={img_F_03} alt="Cube" />
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <h2 id="pll">4/ <acronym title="Permutation Last Layer">PLL</acronym> : Permutation Last Layer</h2>
                    <p class="description">
                        Dans cette dernière étape, on va permuter les pièces de la dernière face pour terminer le cube une bonne fois pour toutes !<br />
                        Il existe 21 algorithmes différents. Une fois la configuration du cube identifiée, cet algorithme permet de résoudre définitement le cube.<br />
                        <a href="/methodFridrichPLL" >Cliquez ici pour la liste des algorithmes</a>
                    </p>
   
                </td>
                <td>
                    <p>
                        <img src={img_F_04} alt="Cube" />
                    </p>
                </td>
            </tr>
        </table>
        </Text>

		</Section>



	</Theme>;
});