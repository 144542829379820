import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Icon, LinkBox, Hr, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { FaHeartbeat, FaMedkit, FaCommentMedical, FaClinicMedical } from "react-icons/fa";
import { IoIosWater } from "react-icons/io";
import { IoMdDownload } from "react-icons/io"; 
import { IoMdPrint } from "react-icons/io";
import { IoMdLock  } from "react-icons/io";
import { FaKey  } from "react-icons/fa";
import { FaRegStickyNote } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaFonticons } from "react-icons/fa";

import { Component } from 'react'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

import Menu from "menu";
import  { BannerSoftware }  from "../bannerSoftware"

import  StackmatTimer_1_1 from "./Virtual_StackMat_Timer_v1_1_Setup.exe"

import { useTranslation } from 'react-i18next';

import Form from "../../form"


export default (() => {

	const { t, i18n } = useTranslation();


	return <Theme theme={theme}>


		
		<Menu idx="1"  halo={false} />

		<BannerSoftware idx={3}/>

		<Section padding="0 0 0 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="30%"
				flex-direction="column"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="10px"
					lg-max-height="366px"
					//src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
					left="-140px"
					max-width="100%"
					object-fit="cover"
					srcSet="stackmatTimer/stackmatTimerIcon.png"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-style="solid"
				width="70%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				flex-direction="column"
				align-items="flex-start"
			>
				<Text
					md-margin="0px 0px 40px 0px"
					lg-margin="0px 0px 40px 0px"
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--indigo"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
					id="software"
				>
					Virtual Stackmat Timer
				</Text>


				<Text
					width="90%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline4"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					{t('software.stackmatTimer.title2')}
				</Text>
				<Box display="flex" margin="0px 0px 0px 0px" align-items="center" sm-width="100%">
					<Image width="24px" height="24px"
					 	src="windows.png" margin="0px 15px 0px 0px" />
					<Text
						margin="0px 0px 0px 0px"
						color="--primary"
						font="--lead"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
					>
						Windows
					</Text>
				</Box>
				<Box display="flex" margin="0px 0px 0px 0px" align-items="center" sm-width="100%">
					<Image width="32px" height="32px" 
						src="rubik_bw.png" margin="0px 15px 0px 0px" 
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--primary"
						font="--lead"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
					>
						Rubik's Cube
					</Text>
				</Box>
				

				<Text
					font="--base"
					opacity=".7"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 25px 0px"
					color="--dark"
				>
					{t('software.stackmatTimer.desc2')}
				</Text>

			
				<Link
					href={StackmatTimer_1_1}
					sm-padding="15px 20px 15px 20px"
					hover-background="rgba(5, 165, 255, 0)"
					border-width="1px"
					border-style="solid"
					font="--lead"
					sm-margin="0px 22px 0px 0px"
					border-color="rgba(255, 255, 255, 0.3)"
					md-width="100%"
					md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					text-decoration-line="initial"
					color="--white"
					margin="0px 44px 0px 0px"
					hover-color="--primary"
					hover-border-color="--color-primary"
					md-text-align="center"
					background="--color-primary"
					padding="15px 30px 15px 30px"
					border-radius="10px"
					sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
				>
					<FontAwesomeIcon icon={faDownload} size="1x"/>   
						{t('software.download')} Virtual Stackmat Timer
				</Link>
			</Box>
		</Section>





		<Section padding="160px 0 24px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
			
			<Image
				src="virtualStackmat.jpg"
			/>
		</Section>



		<Section padding="160px 0 24px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
			

			<Text
				font="--headline4"
				color="--indigo"
			>
				Instructions
			</Text>
			<Text font="--base">Précisez votre pseudo avant de lancer le chrono</Text>
			<Text font="--base">Pour démarrer le chronomètre:</Text>
					<ul>
						<li>appuyez sur le bouton <b>Alt</b> (à gauche de la barre Espace) et <b>Ctrl</b> (à droite de la barre Espace): le voyant rouge s'allume.</li>
						<li>Laissez appuyer 2 secondes, jusqu'à ce que le voyant verts'allume à son tour: le chonomètre est ainsi amorcé.</li>
						<li>Maintenant, le chronomètre se déclenchera dès que l'une des 2 touches sera relâchée.</li>
					</ul>

			<Text font="--base">Pour arrêter le chronomètre, appuyez sur la barre <b>Espace</b></Text>
			<Text font="--base">Le logiciel classe ensuite automatiquement les temps et calcule la moyenne de chaque joueur</Text>


		</Section>



		<Section padding="160px 0 24px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
			<Form title='[Virtual Stackmat Timer]'/>
		</Section>














		





	

	</Theme>;
});