import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";


import Menu from "menu"

export default (() => {
	return <Theme theme={theme}>

	


	 <Menu />

		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 80px 0" quarkly-title="HeroBlock">
			<Override
				slot="SectionContent"
				md-z-index="7"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
			/>
			<Box
				empty-border-width="1px"
				align-items="flex-start"
				md-width="100%"
				display="flex"
				flex-direction="column"
				empty-border-color="LightGray"
				width="70%"
				justify-content="center"
				padding="0px 180px 0px 0px"
				lg-padding="0px 90px 0px 0px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-min-width="64px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					font="--base"
					color="--grey"
					text-transform="uppercase"
					lg-text-align="left"
					lg-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 8px 0px"
					lg-margin="0px 0px 8px 0px"
					sm-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					letter-spacing="0.1em"
				>
					ERROR 404
				</Text>
				<Text
					letter-spacing="1%"
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Nothing here
				</Text>
				<Text
					opacity="0.7"
					font="--base"
					letter-spacing="0.05em"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
				>
					Wrong way
				</Text>
				<Box
					display="flex"
					margin="72px 0px 0px 0px"
					lg-margin="32px 0px 0px 0px"
					md-width="100%"
					md-margin="16px 0px 0px 0px"
				>
					<Link
						href="/"
						sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						sm-padding="15px 13px 15px 13px"
						md-text-align="center"
						hover-color="--primary"
						color="--white"
						padding="15px 30px 15px 30px"
						border-radius="10px"
						border-color="rgba(255, 255, 255, 0.3)"
						border-style="solid"
						text-decoration-line="initial"
						font="--lead"
						border-width="1px"
						lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
						md-width="50%"
						md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						background="--color-primary"
						margin="0px 44px 0px 0px"
						sm-margin="0px 22px 0px 0px"
						transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
						hover-background="--color-white"
						lg-padding="15px 23px 15px 23px"
						md-padding="15px 16px 15px 16px"
					>
						Come back to Home
					</Link>
				
				</Box>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				display="flex"
				md-width="100%"
				empty-border-width="1px"
				empty-border-style="solid"
				width="30%"
				align-items="center"
				justify-content="flex-end"
				md-order="-1"
			>
				<Image
					lg-max-height="400px"
					width="100%"
					max-height="450px"
					md-margin="0px 0px 30px 0px"
					src="recubix.png"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
				/>
			</Box>
		</Section>
		
	</Theme>;
});