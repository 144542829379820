import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Icon, LinkBox, Hr, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { FaHeartbeat, FaMedkit, FaCommentMedical, FaClinicMedical } from "react-icons/fa";
import { IoIosWater } from "react-icons/io";
import { IoMdDownload } from "react-icons/io"; 
import { IoMdPrint } from "react-icons/io";
import { IoMdLock  } from "react-icons/io";
import { FaKey  } from "react-icons/fa";
import { FaRegStickyNote } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaFonticons } from "react-icons/fa";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'


import { useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { useTranslation } from 'react-i18next';


import  Tasky_1_5 from "./Tasky_1.5_Setup.zip"

/*import imlg1 from "/Tasky_1_TODO.png";
import imlg2 from "Tasky_2_CONTACTS.png";
import imlg3 from "Tasky_3_WORK.png";
import imlg4 from "Tasky_4_Notes.png";
import imlg5 from "Tasky_5_Links.png";
import imlg6 from "Tasky_6_Purchases.png";*/

import Menu from "menu";
import  { BannerSoftware }  from "../bannerSoftware"

import Form from "../../form"


function Carousel_SL(props)
{
  // var imlg1 = require("./img/Tasky_1_TODO.png");
  // var imlg2 = require("./img/Tasky_2_CONTACTS.png");

    var items = [
        {
          // name: "Random Name #1",
          // description: "Hello World!",
            img: "Tasky_1_TODO.png",
        },
        {
            // name: "Random Name #2",
            // description: "Hello World!",
            img: "Tasky_2_CONTACTS.png"
        },
        {
            // name: "Random Name #2",
            // description: "Hello World!",
            img: "Tasky_3_WORK.png"
        }
        ,
        {
            // name: "Random Name #2",
            // description: "Hello World!",
            img: "Tasky_4_Notes.png"
        },
        {
            // name: "Random Name #2",
            // description: "Hello World!",
            img: "Tasky_5_Links.png"
        },
        {
            // name: "Random Name #2",
            // description: "Hello World!",
            img: "Tasky_6_Purchases.png"
        }
    ]

    const [firstImgLoaded, setFirstImgLoaded] = useState(false);





	





    return (
        // <Carousel fullWidth  >
        //     {
        //         items.map( (item, i) => <Item swipe={false} key={i} item={item} /> )
        //     }
        // </Carousel>

        // <Stack direction="column"   alignItems="center" justifyContent="center" spacing={2}>

            <Carousel autoPlay={true} interval={2000}  >
              {items.map((item, i) => (
                <Item key={item.name} {...item} style={{marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center"}}/>
              ))}
            </Carousel>
          )
        {/* </Stack> */}
  
    
}


function Item({ name, description, img }) {
	return (
	  <div>
		<h2>{name}</h2>
		<p>{description}</p>
		<div  class="centered">
		  <img src={img} alt="img" />
		</div>
	  </div>
	)
  }
  

export default (() => {

	const { t, i18n } = useTranslation();


	const TaskType = ({title, icon, desc}) =>
		<Box
			align-items="center"
			padding="8px 8px 8px 8px"
			border-width="0px 0px 0px 1px"
		//	lg-padding="32px 16px 16px 16px"
		//	lg-min-height="298px"
		//	height="100%"
			md-align-items="center"
			width="15%"
			//max-height="265px"
			//md-width="50%"
			//margin="0px 0px 0px 0px"
			border-color="rgba(66, 82, 207, 0.1)"
			lg-justify-content="flex-start"
			//sm-padding="32px 16px 16px 16px"
			display="flex"
			sm-justify-content="flex-start"
			flex-direction="column"
			//sm-margin="0px 0px 30px 0px"
			border-style="solid"
			md-justify-content="flex-start"
		//	md-padding="32px 16px 16px 16px"
		>
			<Image
				width="60px"
				//height="80px"
				//margin="0px 0px 8px 0px"
				//src="https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80"
				object-fit="cover"
				//border-radius="50%"
				srcSet={icon}
				sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
			<Text  font="--lead" color="--indigo" sm-text-align="center">
				{title}
			</Text>
			<Text  font="--textBase" color="--darkL1" lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif">
				{desc}
			</Text>
		</Box>



	const FeatureItem = ({title, icon, desc}) => 	
	<Box
		empty-min-height="64px"
		empty-border-width="1px"
		empty-border-color="LightGray"
		empty-min-width="64px"
		empty-border-style="solid"
		padding="5px 5px 5px 5px"
		width="30%"
		//md-width="100%"
		//sm-width="100%"
		//lg-width="50%"
	>
		<Box
			border-color="rgba(66, 82, 207, 0.1)"
			lg-height="100%"
			overflow-x="hidden"
			overflow-y="hidden"
			height="100%"
			width="100%"
			flex-direction="column"
			border-style="solid"
			border-width="2px"
			border-radius="10px"
		//	padding="32px 32px 32px 32px"
			display="flex"
			justify-content="flex-end"
			md-min-height="200px"
			lg-min-height="350px"
			align-items="center"
		>
			<Icon
				font="48px sans-serif"
				category="fa"
				icon={icon}
				size="48px"
				color="--indigo"
			/>
			<Text color="--indigo" font="--lead" >
				{title}
			</Text>
			<Text color="--dark" font="--textBase" >
				{desc}
			</Text>
		</Box>
	</Box>






	return <Theme theme={theme}>


		
		<Menu idx="1" halo={false}/>


		<BannerSoftware idx={1}/>

		
		<Section padding="0 0 0 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="30%"
				flex-direction="column"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="10px"
					lg-max-height="366px"
					//src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
					left="-140px"
					max-width="100%"
					object-fit="cover"
					srcSet="Tasky_ico.png"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-style="solid"
				width="70%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				flex-direction="column"
				align-items="flex-start"
			>
				<Text
					md-margin="0px 0px 40px 0px"
					lg-margin="0px 0px 40px 0px"
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--indigo"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
					id="software"
				>
					Tasky
				</Text>


				<Text
					width="90%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline4"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					{t('software.tasky.title2')}
				</Text>
				<Box display="flex" margin="0px 0px 0px 0px" align-items="center" sm-width="100%">
					<Image width="24px" height="24px"
					 	src="windows.png" margin="0px 15px 0px 0px" />
					<Text
						margin="0px 0px 0px 0px"
						color="--primary"
						font="--lead"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
					>
						Windows
					</Text>
				</Box>
				<Box display="flex" margin="0px 0px 0px 0px" align-items="center" sm-width="100%">
					<Image width="32px" height="32px" 
						src="organisation.png" margin="0px 15px 0px 0px" 
					/>
					<Text
						margin="0px 0px 0px 0px"
						color="--primary"
						font="--lead"
						lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
						md-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
					>
						{t('software.organization')}
					</Text>
				</Box>
				

				<Text
					font="--base"
					opacity=".7"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 25px 0px"
					color="--dark"
				>
					{t('software.tasky.desc2')}
				</Text>

			
				<Link
					href={Tasky_1_5}
					sm-padding="15px 20px 15px 20px"
					hover-background="rgba(5, 165, 255, 0)"
					border-width="1px"
					border-style="solid"
					font="--lead"
					sm-margin="0px 22px 0px 0px"
					border-color="rgba(255, 255, 255, 0.3)"
					md-width="100%"
					md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					text-decoration-line="initial"
					color="--white"
					margin="0px 44px 0px 0px"
					hover-color="--primary"
					hover-border-color="--color-primary"
					md-text-align="center"
					background="--color-primary"
					padding="15px 30px 15px 30px"
					border-radius="10px"
					sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
				>
					<FontAwesomeIcon icon={faDownload} size="1x"/>   
						{t('software.download')} Tasky 1.5
				</Link>
			</Box>
		</Section>
	

		<Section lg-padding="0 0 0 0" padding="60px 0 0 0" md-padding="00px 0 30px 0">
			<Text
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 40px 0px"
				margin="0px 0px 80px 0px"
				font="--headline2"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
				id="software"
			>
				Tasky
			</Text>
		</Section>

		
		
		



			
		<Section 
		//padding="0 0 0 0" 
		//sm-padding="80px 0 24px 0" 
		//md-padding="50px 0 24px 0"
		//align-items="center" 
		//background-color="blue"
		//flex-direction="row"
		

		>
<div class="flex flex-nowrap">
				<Carousel_SL  />
			
			</div>
	
		</Section>





	{/*	<Section >
			<Override slot="SectionContent" justify-content="center" align-items="center" />
			<iframe width="800" height="630"
				src="https://www.youtube.com/embed/tgbNymZ7vqY">
			</iframe>
		</Section>*/}




	<Section>
		<Text font="--base">

      <p>
      Tasky permet de:
      </p>
      <div>
      <ul>
        <li>créer des tâches. Les différents types de tâches sont accessibles avec le clic droit de la souris:</li>
        <ul>
          <li><b>Tâches</b> à cocher</li>
          <li><b>Etiquettes</b>: texte non cochable</li>
          <li><b>Lien</b> (vers un site Internet, un email ou un fichier/dossier sur le PC)</li>
          <li><b>Liste de tâches</b>: lien vers une autre liste de tâches dans Tasky</li>
          <li><b>Contact</b>: pour enregistrer un email, un téléphone, une adresse, rappeler un anniversaire...</li>
          <li><b>Script</b>: pour lancer rapidement des commandes Windows et automatiser des tâches</li>
        </ul>
        <br />
        <li>organiser les différentes tâches en lignes et en arbres de tâches</li>
        <br />
        <li>Associer à chaque tâche:</li>
        <ul>
          <li>un <b>memo</b> complémentaire</li>
          <li>des <b>liens</b> Internet, ou vers un fichier/dossier</li>
          <li>enregistrer un <b>mot de passe</b> (ou en générer un de façon aléatoire grâce au générateur inclus)</li>
          <li>programmer un <b>rappel</b></li>
        </ul>
        <br />
        <li>personnaliser les tâches (couleur, gras, italique, couleur de fond)</li>
        <li>organiser les tâches en les déplaçant intuitivement avec la souris</li>
        <li>créer autant de listes que souhaité</li>
        <li>crypter des listes de tâches pour sécuriser vos mots de passe par exemple</li>
        <li>filter les tâches à afficher</li>
        <li>exporter les tâches vers le presse-papier ou les imprimer</li>
      </ul>
      </div>
    
    {/* </Stack> */}

    <br /><br /><br /><br />

    <div>
      Ce logiciel peut ainsi répondre à de nombreux usages pour améliorer la productivité. Par exemple, vous pouvez l'utiliser pour:
      <ul>
        <li>créer des listes de tâches organisées par priorité</li>
        <li>vous rappeler des rendez-vous importants</li>
        <li>avoir un outil de prises de notes/memo</li>
        <li>avoir un gestionnaire de liens Internet favoris</li>
        <li>avoir un gestionnaire/générateur de mots de passe protégé</li>
        <li>avoir une liste de contacts avec des rappels d'anniversaire!</li>
        <li>créer une liste de courses à imprimer, avec les articles organisés par catégories/rayons pour gagner du temps en magasin!</li>
        <li>lancer des scripts simples pour effectuer des tâches répétitives rapidement</li>
      </ul>
	  </div>
	  </Text>
	</Section>










		<Section padding="0 0 0 0" md-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				justify-content="center"
				align-items="flex-start"
				flex-direction="column"
				width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				display="flex"
			>
				<Text
					margin="0px 0px 38px 0px"
					font="--headline3"
					color="--indigo"
					md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					text-align="left"
				>
					Types de tâches
				</Text>
				<Hr
					border-style="solid"
					margin="0px 0px 0px 0px"
					height="0px"
					lg-display="none"
					width="100%"
					border-color="rgba(66, 82, 207, 0.1)"
					border-width="2px 0 0 0"
				/>
			</Box>
		</Section>





		<Section >
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<TaskType title="Tâches" icon="img/Tasky/task.png" desc="Cases à cocher pour suivre l'avancement de tâches" />
			<TaskType title="Etiquettes de titre" icon="img/Tasky/label.png" desc="Mise en forme des listes" />
			<TaskType title="Liens" icon="img/Tasky/link.png" desc="Stockez des liens vers un site Internet, un email ou un fichier/dossier" />
			<TaskType title="Listes" icon="img/Tasky/lists.png" desc="Lien vers une autre liste" />
			<TaskType title="Contact" icon="img/Tasky/contact.png" desc="Enregistrez un email, un téléphone, une adresse, un anniversaire..." />
			<TaskType title="Scripts" icon="img/Tasky/script.png" desc="pour automatiser des tâches" />
		</Section>







		<Section md-padding="60px 0 60px 0" padding="84px 0 60px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />

			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				justify-content="center"
				align-items="flex-start"
				flex-direction="column"
				width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				display="flex"
			>
				<Text
					margin="0px 0px 38px 0px"
					font="--headline3"
					color="--indigo"
					md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					text-align="left"
				>
					Fonctionnalités annexes
				</Text>

			</Box>

			<FeatureItem title="Alertes" icon={FaBell} desc="Programmez un rappel pour vos tâches" />
			<FeatureItem title="Mots de passe" icon={FaKey} desc="Stockez et générez vos mots de passe" />
			<FeatureItem title="Memo" icon={FaRegStickyNote} desc="Sauvegardez des notes plus détaillées" />
			<FeatureItem title="Cryptage de listes" icon={IoMdLock} desc="Protégez vos listes par mot de passe pour garantir la confidentialité des données" />
			<FeatureItem title="Mise en forme" icon={FaFonticons} desc="Ajoutez de la couleur et du style au texte" />
			<FeatureItem title="Impression" icon={IoMdPrint} desc="Imprimez vos listes de tâches" />
			<FeatureItem title="Import favoris Internet" icon={IoIosWater} desc="Importez vos favoris directement depuis votre navigateur Internet" />
			<FeatureItem title="Import Keepass" icon={IoIosWater} desc="Importez vos mots de passe depuis Keepass" />
		</Section>







		<Section padding="160px 0 24px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
			<Form title='[Tasky]'/>
		</Section>


	</Theme>;
});