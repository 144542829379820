import { React, useEffect } from "react";
import theme from "theme";
import { Theme, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";

import Menu from "menu";

import Stack from "@mui/material/Stack"

import { BannerFridrich } from "./bannerFridrich";

import './methodFridrich.css';



  

export default (() => {
	useEffect(() => {
    // Code JavaScript ici
    const script = document.createElement('script');
    script.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js";
    script.async = true;
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.src = "https://www.recubix.fr/roofpig_and_three.min.js";
    script2.async = true;
    document.head.appendChild(script2);

    // Cleanup si nécessaire (si le script est temporaire par exemple)
    return () => {
      document.head.removeChild(script);
		  document.head.removeChild(script2);
    };
  }, []); // Le tableau vide [] signifie que l'effet ne s'exécutera qu'une seule fois après le montage

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Recubix.fr
			</title>
			<meta name={"description"} content={"Recubix: applications par Sébastien Labonne."} />
			<meta property={"og:title"} content={"Recubix.fr"} />
			<meta property={"og:description"} content={"Recubix: applications par Sébastien Labonne."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/OGimage.png?v=2021-09-21T16:25:40.647Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon.svg?v=2021-09-21T16:18:29.306Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon270.svg?v=2021-09-21T16:18:07.731Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>

		<Menu idx="2" halo={false}/>

    <BannerFridrich idx={3} />

		<Section lg-padding="0 0 0 0" padding="0 0 0 0" md-padding="0 0 0 0">
			<Text
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 40px 0px"
				margin="0px 0px 30px 0px"
				font="--headline2"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
				id="rubik"
			>
				Méthode Fridrich - PLL
			</Text>
		</Section>




		<Section lg-padding="0 0 0 0" padding="0 0 0 0" md-padding="0 0 0 0">

			
        <div>
        La probabilité de rencontrer chacun des cas est indiqué en haut à gauche de chaque case
        <Text
				margin="30px 0px 20px 0px"
				font="--headline4"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
			>
				Les coins sont en place
			</Text>
      <Stack direction="row" spacing={2}>		
        <div class="roofpig" data-config="alg=B2 U L' R B2 L R' U B2| flags=showalg">1/18</div>
        <div class="roofpig" data-config="alg=B2 U' L' R B2 L R' U' B2| flags=showalg">1/18</div>
        <div class="roofpig b" data-config="alg=x' R U' R' U D R' D U' R' U R D2 F| flags=showalg">1/36</div>		
        <div class="roofpig c" data-config="alg=M2 U M2 U2 M2 U M2| flags=showalg">1/72</div>	
       </Stack>


       <Text
				margin="30px 0px 20px 0px"
				font="--headline4"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
			>
				Les arêtes sont en place
			</Text>
      <Stack direction="row" spacing={2}>		
        <div class="roofpig" data-config="alg=x R' U R' D2 R U' R' D2 R2| flags=showalg">1/18</div>
        <div class="roofpig" data-config="alg=x L U' L D2 L' U L D2 L2| flags=showalg">1/18</div>
        <div class="roofpig b" data-config="alg=F R' F' L F R F' L2 x' U' R U L U' R' U| flags=showalg">1/36</div>	
       </Stack>







       <Text
				margin="30px 0px 20px 0px"
				font="--headline4"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
			>
				2 coins adjacents en place
			</Text>

      <Stack direction="row" spacing={2}>	
        <div class="roofpig" data-config="alg=R U R' U' R' F R2 U' R' U' R U R' F'| flags=showalg">1/18</div>	
        <div class="roofpig" data-config="alg=x' R2 U' R x D2 r' D r D2 x' R D R U R' D'| flags=showalg">1/18</div>
        <div class="roofpig" data-config="alg=U' L2 F' L' U' L' U L F L' U2 L U2 L'| flags=showalg">1/18</div>
        <div class="roofpig" data-config="alg=U R2 F R U R U' R' F' R U2 R' U2 R| flags=showalg">1/18</div>			
       </Stack>
       <Stack direction="row" spacing={2}>	
	
        <div class="roofpig" data-config="alg=L' U R U' L U2 R' U R U2 R'| flags=showalg">1/18</div>
        <div class="roofpig" data-config="alg=R U' L' U R' U2 L U' L' U2 L| flags=showalg">1/18</div>		
       </Stack>




       <Text
				margin="30px 0px 20px 0px"
				font="--headline4"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
			>
				2 coins opposés en place
			</Text>
      <Stack direction="row" spacing={2}>	
      <div class="roofpig" data-config="alg=L' U R U' L U L' U R' U' L U2 R U2 R' | flags=showalg" background="yellow">1/18</div>		
      <div class="roofpig" data-config="alg=F R U' R' U' R U R' F' R U R' U' R' F R F'| flags=showalg">1/18</div>	
        <div class="roofpig c" data-config="alg=R U' L d2 L' U L R' U' R U2 r' F l' U'| flags=showalg">1/72</div>	
        <div class="roofpig c" data-config="alg=L' U R' U2 L U' L' R U R' U2 L U' R U| flags=showalg">1/72</div>	

      </Stack>


  
	
      <Text
				margin="30px 0px 20px 0px"
				font="--headline4"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
			>
				1 coin et une arête adjacente en place
			</Text>


          <Stack direction="row" spacing={2}>		
            <div class="roofpig" data-config="alg=L2 u L' U L' U' L u' r2 y' R' D R| flags=showalg">1/18</div>	
            <div class="roofpig" data-config="alg=R2 u' R U' R U R' u l2 y' x2 R U' R'| flags=showalg">1/18</div>	
            <div class="roofpig" data-config="alg=R U R' y' R2 u' R U' R' U R' u R2| flags=showalg">1/18</div>	
            <div class="roofpig" data-config="alg=L' U' L y' R2 u R' U R U' R u' R2 | flags=showalg">1/18</div>	
          </Stack>
</div>


		</Section>



	</Theme>;
});