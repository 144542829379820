import {React, useEffect} from "react";
import Index from "pages/index";
import Page404 from "pages/page404";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import Software from "Software/software"
import Tasky from "Software/Tasky/tasky";
import RubikTeacher from "Software/RubikTeacher/rubikTeacher";
import VirtualStackmatTimer from "Software/VirtualStackmatTimer/virtualStackmatTimer"

import Rubik from "Rubik/rubik"
import MethodFridrich from "Rubik/MethodFridrich/methodFridrich";
import MethodFridrichPLL from "Rubik/MethodFridrich/methodFridrichPLL";
import MethodFridrichOLL from "Rubik/MethodFridrich/methodFridrichOLL";
import MethodLayer from "Rubik/MethodLayer/methodLayer";

import Links from "Links/links"

import Contact from "Contact/contact"
import { startTransition } from "react";



const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
`;

export default () => {

    return (


    <Router>
        <GlobalStyles />

        <Switch>
        	<Route exact path='/' component={Index}/>
			<Route exact path='/index' component={Index}/>

            <Route exact path='/software' component={Software}/>
                <Route  path='/tasky' component={Tasky}/>
                <Route exact path='/rubikTeacher' component={RubikTeacher}/>
                <Route exact path='/virtualStackmatTimer' component={VirtualStackmatTimer}/>

            <Route exact path='/rubik' component={Rubik}/>
                <Route exact path='/methodLayer' component={MethodLayer}/>
                <Route exact path='/methodFridrich' component={MethodFridrich}/>
                    <Route exact path='/methodFridrichPLL' component={MethodFridrichPLL}/>
                    <Route exact path='/methodFridrichOLL' component={MethodFridrichOLL}/>

            <Route exact path='/mylinks' component={Links}/>

            <Route exact path='/contact' component={Contact}/>

			<Route component={Page404}/>
        </Switch>
    </Router>
);
}
