import { React, useState, Component } from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Icon, LinkBox, Hr, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";

import Menu from "menu";

import { useTranslation } from 'react-i18next';



function ItemLink ({title="", url="", icon_="", desc=""}) {
	return (
	<>
	<Link
		href={url}
		style={{ textDecoration: 'none' }}
	>
	<Box
		display="flex"
		width="100%"
		//border-style="solid"
		border-radius="10px"
		height="50px"
		md-border-radius="0px"
		margin="0px 0px 20px 0px"
		sm-margin="0px 0px 30px 0px"
		//padding="0px 0px 0px 0px"
		border-color="rgba(66, 82, 207, 0.1)"
		border-width="2px"
		flex-wrap="wrap"
		md-border-width="0px 0px 2px 0px"
	>
		<Box width="6%" display="flex" >
			<Image
				//margin="0 0px 0px 0px"
			//	object-fit="cover"
				width="40px"
				border-radius="10px"
				//md-margin="0px 0px 0px 0px"
				srcSet={icon_}
				sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
		</Box>
			
		<Box
			//md-width="100%"
			//padding="32px 17% 32px 62px"
			display="flex"
			//lg-width="50%"
			position="relative"
			//lg-padding="32px 30px 32px 32px"
			//md-padding="32px 30px 32px 0px"
			width="92%"
			flex-direction="row"
			justify-content="left"
		>
		
			<Text
				//margin="0px 0px 0px 0px"
				font="--base"
				
				color="--indigo"
				width="30%"
				lg-font="600 28px/1.3 &quot;Inter&quot;, sans-serif"
			>
				<b>{title}</b>
			</Text>

					
			<Text
				//margin="0px 0px 0px 0px"
				font="--base"
				color="--indigo"
				//width="100%"
				lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"

			>
				{desc}
			</Text>
		</Box>
	</Box>
</Link>
</>
)};





export default (() => {

	const { t, i18n } = useTranslation();


	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Recubix.fr
			</title>
			<meta name={"description"} content={"Recubix: applications par Sébastien Labonne."} />
			<meta property={"og:title"} content={"Recubix.fr"} />
			<meta property={"og:description"} content={"Recubix: applications par Sébastien Labonne."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/OGimage.png?v=2021-09-21T16:25:40.647Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon.svg?v=2021-09-21T16:18:29.306Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon270.svg?v=2021-09-21T16:18:07.731Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>

		<Menu idx="3"/>

		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 30px 0" quarkly-title="HeroBlock" md-padding="36px 0 60px 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="70%"
				padding="0px 180px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 8px 0px"
					color="--grey"
					lg-text-align="left"
					font="--base"
					text-transform="uppercase"
					lg-margin="0px 0px 8px 0px"
					sm-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
				>
					
				</Text>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					{t('links.title')}
				</Text>
				<Text
					font="--base"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
				>
					
				</Text>

				<Text
					font="--headline4"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
				>

				</Text>
				
			</Box>
			<Box
				empty-border-style="solid"
				width="20%"
				display="flex"
				justify-content="flex-end"
				empty-border-width="1px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				align-items="center"
				md-width="100%"
				md-order="-1"
				empty-min-width="64px"
			>
				<Image
					lg-max-height="400px"
					width="80%"
					max-height="450px"
					md-margin="0px 0px 30px 0px"
					src="https://uploads.quarkly.io/6123949e957019001e284458/images/iPhone%20X.png?v=2021-09-21T20:44:05.312Z"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
					srcSet="link.png"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>


		<Section 
			margin = "20px 0 100px 0"
		>
			<Text font="--headline4" margin="30px 0 0px 0">{t('links.security')}</Text>
			<ItemLink title="Keepass" url="https://keepass.info/" icon_="links/keepass.png" desc={t('links.keepass')}/>

			<Text font="--headline4" margin="30px 0 0px 0">{t('links.utilities')}</Text>
			<ItemLink title="Ditto Clipboard manager" url="https://ditto-cp.sourceforge.io/" icon_="links/ditto.png" desc={t('links.ditto')}/>
			<ItemLink title="Agent Ransack" url="https://www.mythicsoft.com/agentransack/" icon_="links/agentransack.png" desc={t('links.agentRansack')}/>
			<ItemLink title="Greenshot" url="https://getgreenshot.org/" icon_="links/greenshot.png" desc={t('links.greenshot')}/>
			<ItemLink title="Wiztree" url="https://diskanalyzer.com/download" icon_="links/wiztree.png" desc={t('links.wiztree')}/>

			<Text font="--headline4" margin="30px 0 0px 0">{t('links.image')}</Text>
			<ItemLink title="The Gimp" url="https://www.gimp.org/" icon_="links/gimp.png" desc={t('links.gimp')}/>
			<ItemLink title="Paint.net" url="https://www.getpaint.net/index.html" icon_="links/paint.webp" desc={t('links.paint')}/>

			<Text font="--headline4" margin="30px 0 0px 0">{t('links.video')}</Text>
			<ItemLink title="Da Vinci Resolve" url="https://www.blackmagicdesign.com/fr/products/davinciresolve" icon_="links/davinci.png" desc={t('links.davinciresolve')}/>

			<Text font="--headline4" margin="30px 0 0px 0">3D</Text>
			<ItemLink title="Blender" url="https://www.blender.org" icon_="links/blender.png" desc={t('links.blender')}/>

			<Text font="--headline4" margin="30px 0 0px 0">{t('links.dev')}</Text>
			<ItemLink title="Notepad++" url="https://notepad-plus-plus.org/" icon_="links/notepad.png" desc={t('links.notepad')}/>
			<ItemLink title="Qt" url="https://www.qt.io/" icon_="links/qt.png" desc={t('links.qt')}/>	
			<ItemLink title="Visual Studio Code" url="https://code.visualstudio.com/" icon_="links/visualstudiocode.png" desc={t('links.visualstudiocode')} />
			<ItemLink title="Fork" url="https://git-fork.com/" icon_="links/fork.png" desc={t('links.fork')}/>
			<ItemLink title="Python" url="https://www.python.org/" icon_="links/python.png" desc={t('links.python')}/>
			<ItemLink title="FileZilla" url="https://filezilla-project.org/" icon_="links/filezilla.png" desc={t('links.filezilla')} />
			<ItemLink title="CMake" url="https://cmake.org/" icon_="links/cmake.png" desc={t('links.cmake')} />
		</Section>


		
		
		



{/*
		<Section>


			<Link href="https://ditto-cp.sourceforge.io/" activeClassName="active"><Image
					lg-max-height="400px"
					width="100%"
					max-height="450px"
					md-margin="0px 0px 30px 0px"
					src="https://uploads.quarkly.io/6123949e957019001e284458/images/iPhone%20X.png?v=2021-09-21T20:44:05.312Z"
					lg-position="relative"
					lg-left="-5px"
					max-width="20px"
					srcSet="https://store-images.s-microsoft.com/image/apps.21473.13510798887950305.d9b285e1-1829-480b-a018-980604fa17cc.df6c59c8-9b0e-4195-a06e-84b9bc76ccc0?h=210"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>Ditto</Link>
			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">Keepass</Link>
			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">Greenshot</Link>
			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">Agent Ransack</Link>
			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">Wiztree</Link>

			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">Notepad++</Link>
			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">paint.net</Link>
			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">The Gimp</Link>
			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">Blender</Link>

			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">Qt</Link>
			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">Fork</Link>
			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">Filezilla</Link>
			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">Visual Studio Code</Link>
			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">DBeaver</Link>

			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">VLC</Link>
			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">Audacity</Link>

			<Link to="www.linkedin.com/in/slabonne" activeClassName="active">Musescore</Link>
		</Section>

		*/}
	</Theme>;
});