import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Icon, LinkBox, Hr, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { FaHeartbeat, FaMedkit, FaCommentMedical, FaClinicMedical } from "react-icons/fa";
import { IoIosWater } from "react-icons/io";
import Menu from "menu";

import { useTranslation } from 'react-i18next';
import { GroupSoftware } from "./bannerSoftware";


export default (() => {
	const { t, i18n } = useTranslation();


	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Recubix.fr
			</title>
			<meta name={"description"} content={"Recubix: applications par Sébastien Labonne."} />
			<meta property={"og:title"} content={"Recubix.fr"} />
			<meta property={"og:description"} content={"Recubix: applications par Sébastien Labonne."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/OGimage.png?v=2021-09-21T16:25:40.647Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon.svg?v=2021-09-21T16:18:29.306Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon270.svg?v=2021-09-21T16:18:07.731Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>

		<Menu idx="1"/>

		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 30px 0" quarkly-title="HeroBlock" md-padding="36px 0 60px 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="70%"
				padding="0px 180px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					{t('software.title')}
				</Text>


				<GroupSoftware />


				{/* Logo Youtube */}
				<LinkBox
					display="flex"
					href="https://www.youtube.com/@recubix/videos"
				>
					<Image
						srcSet="youtube.png"
						height="40px"
						margin="10px 0 0 0px"
					/>
				</LinkBox>
			</Box>

			<Box
				empty-border-style="solid"
				width="20%"
				display="flex"
				justify-content="flex-end"
				empty-border-width="1px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				align-items="center"
				md-width="100%"
				md-order="-1"
				empty-min-width="64px"
			>
				<Image
					lg-max-height="400px"
					width="100%"
					max-height="450px"
					md-margin="0px 0px 30px 0px"
					src="https://uploads.quarkly.io/6123949e957019001e284458/images/iPhone%20X.png?v=2021-09-21T20:44:05.312Z"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
					srcSet="software.png"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>


		


		<Section lg-padding="60px 0 40px 0" padding="60px 0 100px 0" md-padding="30px 0 30px 0">


			<Box
				display="flex"
			>
				<Image
					src="windows.png"
					margin="0 15px 0 0"
				/>
				<Text
					//md-margin="0px 0px 40px 0px"
					//lg-margin="0px 0px 40px 0px"
					//margin="0px 0px 80px 0px"
					font="--headline4"
					color="--indigo"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Windows
				</Text>
			</Box>

			<Text
				font="--base"
			>
				{t('software.windowsTxt')}
			</Text>

			<Box
				display="flex"
				margin="0 0px 0 40px"
				//width="20%"
			>
				<Image
					src="organisation.png"
					margin="0 10px 30px 0"
					width="40px"
				/>
			<Text
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 40px 0px"
				margin="50px 40px 80px 0px"
				font="--headline4"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
			>
				{t('software.organization')}
			</Text>
			</Box>


			<Link
				href="/tasky"
			//	width="50%"
				style={{ textDecoration: 'none' }}
			>
			<Box
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				border-radius="10px"
				min-height="100px"
				md-border-radius="0px"
				padding="0px 0px 0px 0px"
				border-style="solid"
				display="flex"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
				width="100%"
				border-color="rgba(66, 82, 207, 0.1)"
				md-border-color="rgba(66, 82, 207, 0.1)"
				background="#eaf2f8"
			>
				<Box display="flex" lg-width="20%" md-width="100%" width="10%">
					<Image
						margin="-1px 0px -1px 20px"
						src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
					//	object-fit="cover"
						width="200px"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						srcSet="Tasky_ico.png"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>


				<Box
					padding="32px 17% 32px 62px"
					width="60%"
					justify-content="space-between"
					md-width="100%"
					md-padding="32px 30px 32px 0px"
					display="flex"
					flex-direction="column"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
				>
					<LinkBox
						bottom="auto"
						lg-right="16px"
						lg-top="49px"
						position="absolute"
						right="32px"
						top="32px"
						left="auto"
						href="/tasky"
					>
						<Image
							width="48px"
							height="48px"
							top="32px"
							left="auto"
							lg-height="24px"
							src="arrow-right.svg"
							position="static"
							right="32px"
							bottom="auto"
							lg-width="24px"
						/>
					</LinkBox>
					<Text
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
						margin="0px 0px 8px 0px"
						font="--headline4"
					>
						Tasky
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--darkL1">
						{t('software.tasky.desc')}
					</Text>
				</Box>
			</Box>
			</Link>

			<Box
				display="flex"
				margin="0 0px 0 40px"
			>
				<Image
					src="rubik_bw.png"
					margin="0 10px 0 0"
					width="50px"
				/>
			<Text
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 40px 0px"
				margin="40px 0px 40px 0px"
				font="--headline4"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
			>
				Rubik's Cube
			</Text>
			</Box>


			<Link 
				href="/rubikTeacher"
				style={{ textDecoration: 'none' }}
			>
			<Box
				display="flex"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				md-border-width="0px 0px 2px 0px"
				margin="0px 0px 20px 0px"
				width="100%"
				border-style="solid"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				border-radius="10px"
				flex-wrap="wrap"
				min-height="100px"
				md-border-radius="0px"
				background="#eaf2f8"
			>
				<Box
					md-order="-1"
					width="10%"
					display="flex"
					lg-width="50%"
					md-width="100%"
				>
					<Image
						//object-fit="cover"
						width="200px"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						margin="-1px -1px -1px 20px"
					//	src="https://images.unsplash.com/photo-1513315849661-23219170a967?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
						srcSet="RubikTeacher_ico.webp"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					padding="32px 17% 32px 62px"
					width="60%"
					display="flex"
					position="relative"
					md-padding="32px 30px 32px 0px"
					flex-direction="column"
					justify-content="space-between"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
					md-width="100%"
				>
					<LinkBox
						lg-right="16px"
						lg-top="49px"
						position="absolute"
						right="32px"
						top="32px"
						left="auto"
						bottom="auto"
						href="/rubikTeacher"
					>
						<Image
							src="arrow-right.svg"
							left="auto"
							lg-height="24px"
							top="32px"
							bottom="auto"
							lg-width="24px"
							width="48px"
							height="48px"
							position="static"
							right="32px"
						/>
					</LinkBox>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline4"
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						Rubik Teacher
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--darkL1">
						{t('software.rubikTeacher.desc')}
					</Text>
				</Box>
			</Box>
			</Link>


			<Link
				href="virtualStackmatTimer"
				style={{ textDecoration: 'none' }}
			>
			<Box
				display="flex"
				width="100%"
				border-style="solid"
				border-radius="10px"
				min-height="100px"
				md-border-radius="0px"
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
				background="#eaf2f8"
			>
				<Box md-width="100%" width="10%" display="flex" lg-width="20%">
					<Image
						margin="0px 0px -1px 20px"
						//src="https://images.unsplash.com/photo-1612531385446-f7e6d131e1d0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
						//object-fit="cover"
						width="150px"
						//border-radius="10px"
						md-margin="0px 0px 0px 0px"
						srcSet="stackmatTimer/stackmatTimerIcon.png"
					//	sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					md-width="100%"
					padding="32px 17% 32px 62px"
					display="flex"
					lg-width="50%"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					md-padding="32px 30px 32px 0px"
					width="60%"
					flex-direction="column"
					justify-content="space-between"
				>
					<LinkBox
						position="absolute"
						right="32px"
						top="32px"
						bottom="auto"
						left="auto"
						lg-right="16px"
						lg-top="49px"
						href="/virtualStackmatTimer"
					>
						<Image
							lg-width="24px"
							lg-height="24px"
							right="32px"
							top="32px"
							left="auto"
							bottom="auto"
							width="48px"
							height="48px"
							src="arrow-right.svg"
							position="static"
						/>
					</LinkBox>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline4"
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						Virtual Stackmat Timer
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--darkL1">
					{t('software.stackmatTimer.desc')}
					</Text>
				</Box>
			</Box>
			</Link>

		</Section>


		<Section
			padding="120px 0 120px 0"
		>
			<Box 
				display="flex"
				//flex-wrap="wrap"
				//flex-direction="row"
				//justify-content="left"
				//align-items="flex-start"
				
			>
				<Box >
					<Image
						src="TI-89.png"
						width="45px"
					/>
				</Box>
				<Box 

				>
					<Text
						md-margin="0px 0px 40px 0px"
						//lg-margin="60px 0px 60px 0px"
						margin="30px 0px 50px 20px"
						font="--headline4"
						color="--indigo"
						lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
						md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
					>
						TI-89
					</Text>
				</Box>
			</Box>

			<Text
				font="--base"
			//	margin="200px 0 0 0"
			>
				{t('software.ti89.desc')}
			</Text>

			{/*LE MAILLON FAIBLE*/}
			<Link 
				href="https://www.ticalc.org/archives/files/fileinfo/348/34866.html"
				style={{ textDecoration: 'none' }}
			>
			<Box
				display="flex"
				width="100%"
				border-style="solid"
				border-radius="10px"
				min-height="80px"
				md-border-radius="0px"
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
				background-color="#eaf2f8"
			>
				<Box md-width="100%" width="15%" display="flex" lg-width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						srcSet="ti_maillon.gif"
					//	sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					md-width="100%"
					padding="32px 17% 32px 62px"
					display="flex"
					lg-width="50%"
					position="relative"
				//	lg-padding="32px 30px 32px 32px"
					md-padding="32px 30px 32px 0px"
					width="60%"
					flex-direction="column"
					justify-content="space-between"
				>
					<Box
						position="absolute"
						right="32px"
						top="32px"
						bottom="auto"
						left="auto"
						lg-right="16px"
						lg-top="49px"
			
					>
						<Image
							lg-width="24px"
							lg-height="24px"
							right="32px"
							top="32px"
							left="auto"
							bottom="auto"
							width="48px"
							height="48px"
							src="arrow-right.svg"
							position="static"
						/>
					</Box>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline4"
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						{t('software.ti89.maillon.title')}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--darkL1">
						{t('software.ti89.maillon.desc')}
					</Text>
				</Box>
			</Box>
			</Link>


			{/*DES CHIFFRES ET DES LETTRES*/}
			<Link
				href="https://www.ticalc.org/archives/files/fileinfo/347/34779.html"
				style={{ textDecoration: 'none' }}
			>
			<Box
				display="flex"
				width="100%"
				border-style="solid"
				border-radius="10px"
				min-height="80px"
				md-border-radius="0px"
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
				background="#eaf2f8"
			>
				<Box md-width="100%" width="15%" display="flex" lg-width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						srcSet="ti_dcedl.gif"
						//sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					md-width="100%"
					padding="32px 17% 32px 62px"
					display="flex"
					lg-width="50%"
					position="relative"
					//lg-padding="32px 30px 32px 32px"
					md-padding="32px 30px 32px 0px"
					width="60%"
					flex-direction="column"
					justify-content="space-between"
				>
					<Box
						position="absolute"
						right="32px"
						top="32px"
						bottom="auto"
						left="auto"
						lg-right="16px"
						lg-top="49px"
						href="https://www.ticalc.org/archives/files/fileinfo/347/34779.html"
					>
						<Image
							lg-width="24px"
							lg-height="24px"
							right="32px"
							top="32px"
							left="auto"
							bottom="auto"
							width="48px"
							height="48px"
							src="arrow-right.svg"
							position="static"
						/>
					</Box>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline4"
						color="--indigo"
						width="85%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						{t('software.ti89.dcedl.title')}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						{t('software.ti89.dcedl.desc')}
					</Text>
				</Box>
			</Box>
			</Link>
		</Section>
		


		


	</Theme>;
});